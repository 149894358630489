import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    success: {
      main: '#00b5b0'
    },
    info: {
      main: '#2F3B4A'
    },
    error: {
      main: '#ff432d'
    },
    primary: {
      main: '#2F3B4A'
    },
    warning: {
      main: '#FF9300',
    }
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true
      }
    },
  },
  typography: {
    h1: {
      fontSize: '28px',
      fontWeight: 400
    },
    h2: {
      fontSize: '24px',
      fontWeight: 400
    }
  }
});
