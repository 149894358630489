import type { NextPage } from 'next';
import Image from 'next/image';
import { Box, Typography } from '@mui/material';
import logo from '../public/images/logo-white.png';

const Maintenance: NextPage = () => {
  return (
    <Box className='maintenance-page'>
      <Box sx={{ marginTop: '35vh', marginLeft: '167px' }}>
        <Image
          src={logo}
          alt='Revenue Base Logo'
          width={441}
          height={80}
        />
      </Box>
      <Box sx={{ marginTop: '25px', marginLeft: '167px' }}>
        <Typography
          sx={{ fontSize: '30px', marginTop: '10px', fontWeight: '300' }}
        >
          Sorry, we&apos;re down for scheduled maintenance right now.
        </Typography>
        <Typography
          sx={{ fontSize: '30px', marginTop: '25px', fontWeight: '300' }}
        >
          Please check back soon.
        </Typography>
      </Box>
    </Box>
  );
};

export default Maintenance;